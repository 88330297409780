<template>
  <b-container>
    <FamilyMember />
  </b-container>
</template>

<script>
import FamilyMember from "../components/family/FamilyMember";
export default {
  name: "FamilyDetails",
  components: {
    FamilyMember
  }
};
</script>

<style></style>
